.table_base{
  width: 100%;
}
.table_base table{
  width: 100%;
  border-collapse: collapse;
}
.table_base table th, .table_base table td{
  padding:2px 2px;
  font-size: 17px;
  text-align: center;
  border:1px solid #e7e7e7;
}
.table_base table th{
  background: #f7f7f7;
}
.table_base table td{
  vertical-align: top;
}
.table_base table .prescribe_th{
  background:#d8ecff;
}
.table_base table .prescribe_td{
  color:#0400ff;
}
.table_box{
  min-height: 27px;
  width: 100%;
}
.table_box2{
  min-height: 50px;
  width: 100%;
  text-align: left;
  font-size: 15px;
}
.box_input{
  font-size: 15px;
  width: 100%;
  height: 100%;
  text-align: center;
}
.box_textarea{
  font-size: 15px;
  width: 100%;
}