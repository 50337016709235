.card_wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 25px;
  padding: 10px;
  margin:0 auto;
  max-width: 350px;
}
.card_box{
  border-radius: 10px;
}
.card_box_con{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}
.card_box_con_box{
  position: relative;
  flex-grow: 1;
  padding:10px;
  background: #fff;
  border-radius: 5px;
  min-width: 350px;
  width: 50%;
}

.stu_top_con span{
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
}

.report_msg_textarea{
  width: 100%;
  min-height: 100px;
  border:1px solid #ddd;
  padding-left:5px;
}
.comment_msg_textarea{
  width: 100%;
  min-height: 40px;
  border:1px solid #ddd;
  padding-left:5px;
}
.disable_msg_back{
  background: #eaeaea;
}
.report_daily_report_wrap{
  background: #eaeaea;
  padding-left:5px;
}
.report_daily_report_title{
  font-weight: bold;
}
.report_daily_report_memo{

}